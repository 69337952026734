<script>
	let ar = [1, 3, 5, 7, 9];
	let nums = [2, 4, 6, 8, 10];
	let x = 0;

	function increment(interval = 1) {
	  x += interval;
	}

	function reset(resetNumber = 0) {
	  x = resetNumber;
	}
</script>

<main>
	<div style="text-align:center;">
		<h1>Svelte Counter App</h1>
		<!-- A state is a changing element on our screen. -->
		<h2>{x}</h2>
		<button on:click={() => increment()}>+</button>
		<button on:click={() => increment(-1)}>-</button>
		<button on:click={() => reset()}>RESET</button>

		<br /><br />

		<button on:click={() => increment(2)}>+2</button>
		<button on:click={() => increment(5)}>+5</button>
		<button on:click={() => increment(10)}>+10</button>

		<span>&nbsp&nbsp&nbsp</span>

		<button on:click={() => increment(-2)}>-2</button>
		<button on:click={() => increment(-5)}>-5</button>
		<button on:click={() => increment(-10)}>-10</button>
		
		<h2>Svelte Buttons Generator App</h2>
		<h3>Incrementors</h3>

		{#each ar as v}
			<button on:click={() => increment(v)}>{v}</button>
			<span>&nbsp</span>
		{/each}

		<h3>Decrementors</h3>
		{#each nums as v}
			<button on:click={() => increment(-v)}>{v}</button>
			<span>&nbsp</span>
		{/each}
	</div>
</main>

<style></style>